import React from "react";
import { NavLink } from "react-router-dom";
import "./navigation.scss";
import { AiOutlineHome } from "react-icons/ai";
import { GiEarthAfricaEurope } from "react-icons/gi";
// import { CiLocationOn } from "react-icons/ci";
import { BsInfoCircle } from "react-icons/bs";

const Navigation = () => {
    return (
        <div className="navigationContent">
            <ul>
                <li>
                    <NavLink exact="true" to="/">
                        <AiOutlineHome />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        exact="true"
                        to="/services"
                        className="services-link"
                    >
                        <GiEarthAfricaEurope />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        exact="true"
                        to="/contact"
                        className="contact-link"
                    >
                        <BsInfoCircle />
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink exact="true" to="/about" className="about-link">
                        <BsInfoCircle />
                    </NavLink>
                </li> */}
            </ul>
        </div>
    );
};

export default Navigation;
