import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./services.scss";
import Article from "../../components/Article/Article";
import transfertMoneyPicture from "../../medias/images/moneyTransfert.jpg";
import pickUpPicture from "../../medias/images/pickUp.png";
import mobileAccessoriesPicture from "../../medias/images/mobileAccessories.jpg";
import westernUnion from "../../medias/images/westernUnion.png";
import moneyGram from "../../medias/images/moneyGram.png";

const Services = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <div className="servicesContent">
            <main>
                <div className="partnerWrapper" data-aos="fade-up">
                    <p data-aos="slide-down">Nos partenaires</p>
                    <div className="imgPartnerWrapper">
                        <img
                            src={westernUnion}
                            alt="western union"
                            data-aos="slide-right"
                        />
                        <img
                            src={moneyGram}
                            alt="money gram"
                            data-aos="slide-left"
                        />
                    </div>
                </div>
                <div className="animationWrapper" data-aos="slide-right">
                    <Article
                        id={"argent"}
                        picture={transfertMoneyPicture}
                        alt={"Transfert d'argent"}
                        title={`Des transferts d'argent à portée de main.`}
                        text={`Envoyez simplement et rapidement de l'argent à
                        l'étranger avec Money Gram ou Wester Union.
                        Transfert rapide et sécurisé en France ou à
                        l'étranger. 
                        Vous n'avez jamais effectué de transfert d'argent?
                        Aucun soucis! L'équipe de RC Multi-Services est la
                        pour vous accompagner! Envoyer de l'argent n'a
                        jamais été aussi facile, n'hésitez pas à passer dans
                        notre boutique.`}
                    />
                </div>
                <div className="animationWrapper" data-aos="slide-right">
                    <Article
                        id={"pickUp"}
                        picture={pickUpPicture}
                        alt={"Relais Pickup"}
                        title={`Relais Pick up`}
                        text={`RC Multi-services faisant parti du reseau de relais
                            Pick Up, vous pouvez envoyer ou recevoir vos colis
                            directement dans notre boutique. Profitez de l'efficacité d'un réseau d'experts.
                            Pickup, filiale de la Poste, est le réseau de relais
                            et de consignes numéro 1 en France. Chaque année,
                            des millions de colis sont livrés dans les points
                            Pickup par les transporteurs Chronopost, Colissimo
                            et DPD France.`}
                    />
                </div>
                <div className="animationWrapper" data-aos="slide-right">
                    <Article
                        id={"mobile"}
                        picture={mobileAccessoriesPicture}
                        alt={"Mobile et accessoires"}
                        title={`Mobiles et accessoires`}
                        text={`Vous avez besoin d'un mobile, une protection ou
                            encore une recharge de forfait? Vous trouverez tout
                            ce dont vous avez besoin dans notre boutique. Votre mobile est endommagé ? RC Multi-services met à
                            votre disposition un service de réparation proche de
                            cchez vous. N'hésitez pas à nous appeler si vous
                            avez des questions concernant votre problème, nous
                            nous ferons un plaisir de vous répondre !`}
                    />
                </div>
            </main>
        </div>
    );
};

export default Services;
