import React from "react";
import "./article.scss";

const Article = ({ title, text, picture, alt, id }) => {
    return (
        <article className="articleContent">
            <div className="imgWrapper">
                <img src={picture} alt={alt} />
            </div>
            <div className="textWrapper">
                <h3 id={id}>{title}</h3>
                <p>{text}</p>
            </div>
        </article>
    );
};

export default Article;
