import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Banner from "../../components/Banner/Banner";
import picture from "../../medias/images/openHours.jpg";
import "./contact.scss";

const Contact = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <div className="contactContent">
            <main>
                <Banner picture={picture} />

                <h1>
                    Puisque nous savons que le temps peut vous manquer dans la
                    semaine, nous sommes ouverts tous les jours de la semaine.
                </h1>
                <p>
                    Nos horaires sont susceptibles de changer lors des jours
                    fériés, n'hésitez pas à nous appeler pour avoir plus
                    d'informations.
                </p>
                <div className="infoWrapper">
                    <div className="animationWrapper" data-aos="fade-in">
                        <div className="openHours">
                            <h3>Jours et horaires d'ouverture</h3>
                            <ul>
                                <li>lundi 09:00–18:30</li>
                                <li>mardi 09:00–18:30</li>
                                <li>mercredi 09:00–18:30</li>
                                <li>jeudi 09:00–18:30</li>
                                <li>vendredi 09:00–18:30</li>
                                <li>samedi 09:00–18:30</li>
                                <li>dimanche 10:00–17:00</li>
                            </ul>
                        </div>
                    </div>
                    <div className="animationWrapper" data-aos="fade-in">
                        <div className="contactInfo">
                            <h3>Nous contacter</h3>
                            <a href="tel:+33777837566">
                                <span>07 77 83 75 66</span>
                            </a>
                            <a href="https://goo.gl/maps/WUYr7umcD1DpDLn88">
                                <span>13 Rue des Carmes, 45000 Orléans</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Contact;
