import React from "react";
import Button from "../../components/Button/Button";
import "./notFound.scss";
import errPicture from "../../medias/images/404.gif";

const NotFound = () => {
    const Destination = "/";

    return (
        <div className="err404">
            <div className="imgContent">
                <img src={errPicture} alt="" />
            </div>
            <h2>Oups...</h2>
            <p>Il semblerait que la page que vous recherchiez n'existe pas.</p>
            <div className="buttonContent">
                <Button text={`Accueil`} destination={Destination} />
            </div>
        </div>
    );
};

export default NotFound;
