import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./card.scss";

const Card = ({ title, picture, alt, link }) => {
    return (
        <div className="cardContent">
            <Link to={link}>
                <img src={picture} alt={alt} />
                <h3>{title}</h3>
            </Link>
        </div>
    );
};

export default Card;
