import React, { useState } from "react";
import Navigation from "../Navigation/Navigation";
import "./navBar.scss";
import { BiUpArrow } from "react-icons/bi";

const NavBar = () => {
    const [open, setOPen] = useState(false);
    const toggle = () => {
        setOPen(!open);
    };

    return (
        <div className="navBarContent">
            <BiUpArrow
                icon={BiUpArrow}
                onClick={toggle}
                className={open ? "rotate-up" : "rotate-down"}
            />
            {open && (
                <div className="scale-in-ver-top">
                    <Navigation />
                </div>
            )}
        </div>
    );
};

export default NavBar;
