import React, { useState } from "react";
import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import "./header.scss";

const Header = () => {
    const [fix, setFix] = useState(false);

    function setFixed() {
        if (window.scrollY >= 118.19) {
            setFix(true);
        } else {
            setFix(false);
        }
    }

    window.addEventListener("scroll", setFixed);
    return (
        <div className={fix ? "headerContent sticky" : "headerContent"}>
            <Logo />
            <NavBar />
        </div>
    );
};

export default Header;
