import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./home.scss";
import Button from "../../components/Button/Button";
import Banner from "../../components/Banner/Banner";
import picture from "../../medias/images/banner.jpg";
import Card from "../../components/Card/Card";
import moneyTransferPicture from "../../medias/images/image-moneyTransfer.svg";
import pickUpPicture from "../../medias/images/image-delivery.svg";
import mobileAccessoriesPicture from "../../medias/images/image-mobile.svg";

const Home = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const Color = {
        color: "white",
    };

    const Destination = "https://goo.gl/maps/WUYr7umcD1DpDLn88";

    return (
        <div className="homeContent">
            <main>
                <Banner picture={picture} />
                <section>
                    <h1>
                        Vous accompagner en étant proche de vous. Notre
                        expertise est la solution que nous avons à vous
                        proposer.
                    </h1>
                    <div className="callToAction">
                        <h3>
                            Venez nous rencontrer dans notre boutique située au
                            13, Rue des Carmes, 45000 Orléans.
                        </h3>
                        <Button
                            text={`Nous trouver`}
                            style={Color}
                            destination={Destination}
                        />
                    </div>
                    <div className="cardWrapper">
                        <div className="animationWrapper" data-aos="flip-up">
                            <Card
                                link={"/services#argent"}
                                title={
                                    "Envoyez de l'argent vers la destination de votre choix"
                                }
                                picture={moneyTransferPicture}
                                alt={"Transfert d'argent"}
                            />
                        </div>
                        <div className="animationWrapper" data-aos="flip-up">
                            <Card
                                link={"/services#pickUp"}
                                title={
                                    "Relais Pickup pour envoyer vos colis rapidement"
                                }
                                picture={pickUpPicture}
                                alt={"Colis"}
                            />
                        </div>
                        <div className="animationWrapper" data-aos="flip-up">
                            <Card
                                link={"/services#mobile"}
                                title={
                                    "Vente et réparation de mobile et accessoires"
                                }
                                picture={mobileAccessoriesPicture}
                                alt={"Mobile et accessoires"}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Home;
