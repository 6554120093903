import React from "react";
import { NavLink } from "react-router-dom";
import "./button.scss";
import useAnalyticsEventTracker from "../UseAnalyticsEventTracker/UseAnalyticsEventTracker";

const Button = ({ text, destination }) => {
    const gaEventTracker = useAnalyticsEventTracker("Contact us");
    return (
        <button>
            <NavLink
                to={destination}
                className="buttonLink"
                onClick={() => gaEventTracker("find")}
                rel="noreferrer noopener"
                target="_blank"
            >
                {text}
            </NavLink>
        </button>
    );
};

export default Button;
