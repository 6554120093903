import React from "react";
import "./banner.scss";

const Banner = ({ picture }) => {
    return (
        <div className="bannerContent">
            <img src={picture} alt="banniere" />
        </div>
    );
};

export default Banner;
