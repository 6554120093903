import React from "react";
import "./logo.scss";

const Logo = () => {
    return (
        <div className="logoContent">
            <img src="./images/logo-rc.png" alt="logo du magasin" />
        </div>
    );
};

export default Logo;
