import React from "react";
import "./footer.scss";
import { FaFacebookSquare } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";

const Footer = () => {
    return (
        <footer>
            <div className="footerContent">
                <div className="linkWrapper">
                    <a
                        href="https://www.facebook.com/profile.php?id=100077125189414"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        ""
                        <FaFacebookSquare />
                    </a>
                    <a
                        href="tel:+33777837566"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        ""
                        <MdLocalPhone />
                    </a>
                </div>
                <p>©️imbertf - 2023</p>
            </div>
        </footer>
    );
};

export default Footer;
